import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class MaintenanceInterceptorService implements HttpInterceptor {

	constructor(private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((event: HttpEvent<any>) => {
				// Controlla se la risposta è di tipo HttpResponse
				if (event instanceof HttpResponse) {
					// Controlla se la risposta contiene status = 'down'
					if (event.body?.data?.status === 'down') {
						// Reindirizza alla pagina di manutenzione
            sessionStorage.setItem('maintenance','1')
						this.router.navigate(['/maintenance']);
					}
				}
			})
		);
	}
}
